<template>
  <BaseMenu class="flex-shrink-0" show-arrow>
    <div class="flex items-center xl:space-x-2 cursor-pointer">
      <UsersUserAvatar
        :src="getAvatar(authStore.profile)"
        :abbr="getAbbr(authStore.profile)"
        size="lg"
        class="-mr-2 xl:mr-0"
      >
        <template v-if="authStore.profile?.is_subscription_active" #topBadge>
          <SubscriptionsProBadge />
        </template>
        <template #title>
          <div
            v-if="authStore.profile"
            class="leading-4 text-sm font-medium hidden xl:flex"
          >
            {{ getFullName(authStore.profile) }}
          </div>
          <div
            v-else
            class="h-3 w-20 bg-gray-300 rounded-md animate-pulse hidden xl:flex"
          ></div>
        </template>
        <!-- <template #text>
          <BaseBadge
            v-if="authStore.profile"
            class="bg-primary text-white font-normal hidden xl:inline-flex"
            size="sm"
            theme="none"
          >
            <div>
              {{ authStore.ticket }}
            </div>
          </BaseBadge>
          <div
            v-else
            class="h-3 w-12 mt-2 bg-gray-300 rounded-md animate-pulse hidden xl:flex"
          ></div>
        </template> -->
      </UsersUserAvatar>
    </div>

    <template #menu>
      <div class="rounded-md w-48 bg-white shadow-md mt-2 py-1">
        <BaseLocaleLink to="/users/me">
          <HeaderProfileMenuItem
            icon="outline_user_circle"
            :text="$t('profile.profile')"
          />
        </BaseLocaleLink>

        <BaseLocaleLink to="/contacts">
          <HeaderProfileMenuItem
            icon="outline_question_mark_circle"
            :text="$t('contacts.help')"
          />
        </BaseLocaleLink>
        <HeaderProfileMenuItem
          v-if="!isApp"
          class="cky-banner-element"
          icon="outline_eye_off"
          :text="$t('profile.cookie_privacy')"
        />
        <hr />
        <HeaderProfileMenuItem
          icon="outline_logout"
          :text="$t('profile.logout')"
          @click="authStore.logout()"
        />
      </div>
    </template>
  </BaseMenu>
</template>

<script setup lang="ts">
import { useAuth } from '~/stores/auth'
const { isApp } = useDetect()

const authStore = useAuth()
</script>
