<template>
  <BaseLocaleLink
    v-if="authStore.auth"
    to="/users/me/notifications"
    class="flex md:hidden"
  >
    <BaseButton
      size="xs"
      rounded="full"
      look="none"
      class="text-gray-800 hover:text-gray-600 !px-1 transition"
    >
      <BaseIcon size="sm" name="outline_bell" class="w-[24px] h-[24px]" />
      <div
        v-if="notificationsStore.count > 0"
        style="min-width: 1rem"
        class="absolute top-0 px-1 left-3.5 h-4 rounded-full flex justify-center items-center bg-primary-800 text-white text-xs"
      >
        {{ notificationsStore.count > 9 ? '9+' : notificationsStore.count }}
      </div>
    </BaseButton>
  </BaseLocaleLink>

  <BaseMenu v-if="authStore.auth" class="hidden md:block" @show="onMenuOpen">
    <template #default="{ isVisible }">
      <BaseButton
        size="xs"
        rounded="full"
        look="none"
        class="text-gray-800 hover:text-gray-600 !px-1 transition"
      >
        <BaseIcon
          size="sm"
          name="outline_bell"
          class="w-[24px] h-[24px]"
          :class="isVisible && '[&>svg]:fill-current'"
        />
        <div
          v-if="notificationsStore.count > 0"
          style="min-width: 1rem"
          class="absolute top-0 px-1 left-4 h-4 rounded-full flex justify-center items-center bg-primary-800 text-white text-xs"
        >
          {{ notificationsStore.count > 9 ? '9+' : notificationsStore.count }}
        </div>
      </BaseButton>
    </template>

    <template #menu>
      <div
        ref="menuElement"
        class="rounded-md w-80 max-h-64 overflow-auto bg-white shadow-md mt-2.5 min-h-12"
      >
        <div class="flex flex-col">
          <template
            v-for="item in notificationsStore.list?.hits"
            :key="item.uuid"
          >
            <div
              class="p-3 px-5"
              :class="
                notificationsStore.getNotificationActionPath(item) &&
                'hover:bg-primary-50 cursor-pointer'
              "
              @click="onNotificationClick(item)"
            >
              <div v-if="notificationsStore.isCustomPushMessage(item)">
                <div class="leading-5 text-md">
                  {{ notificationsStore.getCustomPushTitle(item) }}
                </div>
                <div
                  class="leading-5 text-sm mt-2 whitespace-pre-wrap text-gray-900"
                >
                  {{ notificationsStore.getCustomPushText(item) }}
                </div>
              </div>
              <div
                v-else
                class="leading-5 text-md"
                v-html="getMessageTextHtml(item)"
              ></div>
              <div class="text-sm text-gray-700 mt-1">
                {{ notificationsStore.getNotificationRelativeTime(item) }}
              </div>
            </div>
          </template>
        </div>

        <div
          v-if="
            notificationsStore.list.loaded &&
            !notificationsStore.list?.hits.length
          "
          class="flex justify-center p-3 text-sm text-gray-600"
        >
          {{ t('notification_list.no_notifications') }}
        </div>

        <div
          v-if="notificationsStore.list.processing"
          class="flex justify-center p-3"
        >
          <BaseSpinner size="sm" />
        </div>

        <BaseObserve
          v-if="
            !notificationsStore.list.processing &&
            notificationsStore.list.loaded &&
            notificationsStore.list.meta?.current_page !== 0
          "
          empty
          @change="(visible) => visible && notificationsStore.prevPage()"
        />
      </div>
    </template>
  </BaseMenu>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { ChatMessage } from '~/models/chat'
import { useAuth } from '~/stores/auth'

const { t } = useI18n()
const notificationsStore = useNotificationList()
const authStore = useAuth()
const menuElement = ref<null | HTMLElement>(null)
const chatStore = useChat()

async function init() {
  if (!authStore.auth) {
    return
  }
  await Promise.all([
    notificationsStore.getLastPage(),
    notificationsStore.updateNotificationChannel(),
  ])
}

onMounted(async () => {
  if (!useNuxtApp().isHydrating) {
    await init()
  }
})

onServerPrefetch(async () => {
  // it will cause double fetch on SSR, but we take it as it is rare case
  await init()
})

watch(
  () => authStore.auth,
  async () => {
    if (authStore.auth) {
      await init()
    } else {
      notificationsStore.reset()
    }
  }
)

async function onMenuOpen() {
  notificationsStore.reset()
  await init()
  await notificationsStore.readNotifications()
}

async function onNotificationClick(message: ChatMessage) {
  await notificationsStore.onNotificationClick(message)
}

function getMessageTextHtml(message: ChatMessage) {
  return chatStore.getMessageHtml(
    notificationsStore.getNotificationLocalText(message)
  )
}
</script>
